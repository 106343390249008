import React, {useMemo} from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import {HobbiesDetailPropsType} from './type';
import { PageLayout } from '../PageLayout';
import { Container } from '../Container';
import { Root, TwoColumnRoot, Left, Right } from './style';
import { HobbiesDetailSide } from './HobbiesDetailSide';
import { Link } from '../Link';
import {PROJECTS_LIST} from "../../pathTemplates";
import {PageOffset} from "../PageOffset";
import {Typography} from "../Typography";
import {ProjectsEntry} from "../../type";
import {NarrowContainer} from "../NarrowContainer";
import {MDXComponents} from "../Providers/MDXComponents";
import Box from "@mui/material/Box";
import {SkillsV2} from "../SkillsV2";
import {RoundButton} from "../RoundButton";

/**
 * This component is for wrapping up pages that lay in the content/hobbies/ folder.
 * See gatsby-node.js for details.
 */
export const ProjectsDetail = ({
    data,
    path,
}: HobbiesDetailPropsType) => {
    const { mdx } = data ?? {};
    const frontmatter = mdx?.frontmatter as ProjectsEntry;
    const title = frontmatter?.title ?? "";

    const location = useMemo(() => ({ pathname: path }), [path]);

    let year = 0;
    const dateSplit = (frontmatter?.date ?? "").split("-");
    if (dateSplit.length === 3) {
        year = parseInt(dateSplit[0])
    }

    const link = frontmatter?.link ?? "";

    const skills = frontmatter?.skills ?? [];

    return (
        <PageLayout pageContext={mdx} location={location}>
            <Root>
                <TwoColumnRoot container spacing={6}>
                    <Left item md={4} sm={12}>
                        <HobbiesDetailSide data={mdx} />
                    </Left>
                    <Right item md={8} sm={12}>
                        <Container>
                            <Typography
                                variant="h1"
                                component="h1"
                                enableVerticalGutter
                            >
                                {title}
                            </Typography>
                        </Container>
                        {
                            (link !== "")
                            &&
                            <NarrowContainer>
                                <Box marginBottom={1}>
                                    Visit 👉 <Link to={link}>{link}</Link>
                                </Box>
                            </NarrowContainer>
                        }
                        {
                            (year !== 0)
                            &&
                            <NarrowContainer>
                                <Typography variant="h4" component="span">
                                    Year: {year}
                                </Typography>
                            </NarrowContainer>
                        }
                        {
                            skills.length > 0
                            &&
                            <NarrowContainer>
                                <SkillsV2 list={skills.map(skill => skill.code).join(",")} marginTop="1.5rem" />
                            </NarrowContainer>
                        }
                        <MDXProvider components={MDXComponents}>
                            <MDXRenderer pageContext={mdx}>{mdx.body}</MDXRenderer>
                        </MDXProvider>
                        <NarrowContainer>
                            <Box marginTop={5} display="flex" justifyContent="center" width="100%">
                                <Link
                                    to={PROJECTS_LIST}
                                >
                                    <RoundButton>
                                        Back to list
                                    </RoundButton>
                                </Link>
                            </Box>
                        </NarrowContainer>
                        <PageOffset />
                    </Right>
                </TwoColumnRoot>
            </Root>
        </PageLayout>
    );
};

export const ProjectsDetailQuery = graphql`
    query ProjectsDetailQuery($id: String) {
        mdx(id: { eq: $id }) {
            id
            body
            frontmatter {
                title
                keywords
                description
                displayPageTitle
                published
                date
                updatedAt
                learned
                tutorials {
                    url
                    text
                }
                skills {
                    code
                    version
                }
                link
                images {
                    isCover
                    galleryId
                    author
                    source
                    sourceText
                    title
                    image {
                        childImageSharp {
                            gatsbyImageData(
                                layout: CONSTRAINED
                                width: 3000
                                quality: 80
                                placeholder: BLURRED
                            )
                        }
                    }
                }
            }
        }
    }
`;

export default ProjectsDetail;
